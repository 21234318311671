import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
} from "firebase/auth";
import { collection, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { firebaseAuth, firestore } from "../../Firebase";
import JobsList from "../Dashboard/JobsList";
import styles from "./Admin.module.css";
import CreateTemplate from "./CreateTemplate";
import UploadPodcard from "./UploadPodcard";

const Admin = () => {
  const [promptLogin, setPromptLogin] = useState(true);
  const [user, authLoading, authError] = useAuthState(firebaseAuth);
  const [email, setEmail] = useState(user && user.email);
  const [jobs, jobsLoading, jobsError] = useCollectionData(
    query(collection(firestore, "jobs"), where("owner", "==", email))
  );

  let navigate = useNavigate();

  const loadJobs = (e) => {
    e.preventDefault();
    const emailInput = document.getElementById("email");
    setEmail(emailInput.value);
  };

  // If not logged in then prompt to do so
  useEffect(() => {
    if (!authLoading && user == null) {
      setPromptLogin(true);
    } else {
      setPromptLogin(false);
    }
  }, [authLoading, user]);

  useEffect(() => {
    if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      signInWithEmailLink(firebaseAuth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem("emailForSignIn");
          window.history.replaceState(null, null, window.location.pathname);
          // You can access the new user via result.user
        })
        .catch((error) => {
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, []);

  const logout = () => {
    signOut(firebaseAuth)
      .then(() => {
        // Sign-out successful.
        navigate("/login");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <div className={styles.col1}>
          <span className={styles.email}>
            {authLoading ? "Loading..." : user && user.email}
          </span>
        </div>
        <div className={styles.col2}>
          <span className={styles.title}>ADMIN PORTAL</span>
        </div>
        <div className={styles.col3}>
          <Button
            type="button"
            text="Logout"
            outline
            onClick={logout}
            style={{ margin: 0, flexShrink: 0 }}
          />
        </div>
      </div>
      <UploadPodcard />
      <CreateTemplate />
      <form onSubmit={loadJobs}>
        <div className={styles.topBar}>
          <div className={styles.col1}>
            <span>Customer Email</span>
          </div>

          <div className={styles.col2}>
            <Input id="email" placeholder="name@example.com" required={false} />
          </div>
          <div className={styles.col3}>
            <Button
              type="submit"
              text="Load"
              style={{ margin: 0, flexShrink: 0 }}
            />
          </div>
        </div>
      </form>
      <div>
        {promptLogin && (
          <strong>
            It appears you aren't logged in!{" "}
            <a
              href={
                window.location.protocol +
                "//" +
                window.location.host +
                "/login"
              }
            >
              Click here to login.
            </a>
          </strong>
        )}
        <div className={styles.header}>
          <h1>Your Podcards</h1>
        </div>
        <JobsList
          authLoading={authLoading}
          authError={authError}
          jobs={jobs}
          jobsLoading={jobsLoading}
          jobsError={jobsError}
        />
      </div>
    </div>
  );
};

export default Admin;
