import React from "react";

import { UploadState } from "./UploadState";

export default class LegacyRecorder extends React.Component {
  constructor(props) {
    super(props);
    this.channelData = [];
    this.recorder = null;
    this.recordingLength = 0;
    this.sampleRate = null;
    this.AudioContext = window.AudioContext || window.webkitAudioContext;
    this.context = null;
    this.stream = this.props.stream;
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.state !== prevProps.state) {
      switch (this.props.state) {
        case UploadState.NONE:
          console.log("state machine none stop");
          this.stop();
          break;
        case UploadState.RECORD_START:
          console.log("state machine start");
          this.start();
          break;
        case UploadState.RECORD_STOP:
          console.log("state machine stop");
          this.stop();
          break;
        default:
          break;
      }
    }
  }

  // Stop recording on unmount
  componentWillUnmount() {
    this.stop();
  }

  setUpRecording = () => {
    this.context = new this.AudioContext();
    this.audioInput = this.context.createMediaStreamSource(this.props.stream);
    this.sampleRate = this.context.sampleRate;

    let bufferSize = 2048;
    this.recorder = this.context.createScriptProcessor(bufferSize, 1, 1);

    this.audioInput.connect(this.recorder);
    this.recorder.connect(this.context.destination);

    const self = this;
    this.recorder.onaudioprocess = function (e) {
      let channelData = e.inputBuffer.getChannelData(0);
      self.channelData.push(new Float32Array(channelData));
      self.recordingLength += bufferSize;
    };
  };

  mergeBuffers = (channelBuffer, recordingLength) => {
    let result = new Float32Array(recordingLength);
    let offset = 0;
    let lng = channelBuffer.length;
    for (let i = 0; i < lng; i++) {
      let buffer = channelBuffer[i];
      result.set(buffer, offset);
      offset += buffer.length;
    }
    return result;
  };

  writeUTFBytes = (view, offset, string) => {
    let lng = string.length;
    for (let i = 0; i < lng; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  start = async () => {
    this.setUpRecording();
    this.channelData.length = 0;
    this.recordingLength = 0;
  };

  stop = () => {
    // Close mic stream
    this.props.stream.getTracks().forEach((track) => {
      if (track.readyState === "live") {
        track.stop();
      }
    });

    // Flatten the channel down
    this.channelBuffer = this.mergeBuffers(
      this.channelData,
      this.recordingLength
    );

    /* 
    WAV Encoding from http://typedarray.org/from-microphone-to-wav-with-getusermedia-and-web-audio/
    */

    // Create our wav file
    let buffer = new ArrayBuffer(44 + this.channelBuffer.length * 2);
    let view = new DataView(buffer);

    // RIFF chunk descriptor
    this.writeUTFBytes(view, 0, "RIFF");
    view.setUint32(4, 44 + this.channelBuffer.length * 2, true);
    this.writeUTFBytes(view, 8, "WAVE");
    // FMT sub-chunk
    this.writeUTFBytes(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, 1, true); // NumChannels
    view.setUint32(24, this.sampleRate, true); // SampleRate
    view.setUint32(28, this.sampleRate * 1 * 2, true); // Byte Rate = SampleRate * NumChannels * BitsPerSample/8
    view.setUint16(32, 2, true); // Block Align = NumChannels * BitsPerSample/8
    view.setUint16(34, 16, true); // BitsPerSample
    // Data sub-chunk
    this.writeUTFBytes(view, 36, "data");
    view.setUint32(40, this.channelBuffer.length * 2, true); // Subchunk2Size

    // Write the PCM samples
    let lng = this.channelBuffer.length;
    let index = 44;
    let volume = 1;
    for (let i = 0; i < lng; i++) {
      view.setInt16(index, this.channelBuffer[i] * (0x7fff * volume), true);
      index += 2;
    }

    // Final binary blob
    const blob = new Blob([view], { type: "audio/wav" });
    const url = URL.createObjectURL(blob);

    this.props.setAudioData({ blob: blob, url: url, mimeType: "audio/wav" });
  };

  render() {
    return <></>;
  }
}
