import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { firebaseAuth } from "../../Firebase";
import styles from "./Admin.module.css";

const CreateTemplate = () => {
  const handleClick = () => {
    const jobId = document.getElementById("createTemplateJobId").value.trim();
    if (!jobId) {
      alert("Please enter valid job ID");
      return;
    }

    firebaseAuth.currentUser
      .getIdToken(true)
      .then(function (idToken) {
        fetch("https://createtemplate-lxdlato5za-uc.a.run.app", {
          method: "POST",
          body: JSON.stringify({
            jobId: jobId,
          }),
          headers: {
            Authorization: idToken,
            "Content-type": "application/json; charset=UTF-8",
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className={styles.topBar}>
      <div className={styles.col1}>
        <span>Create Template</span>
      </div>
      <div className={styles.col2}>
        <Input id="createTemplateJobId" placeholder="Job ID" required={false} />
      </div>
      <div className={styles.col3}>
        <Button
          onClick={handleClick}
          text="Create"
          style={{ margin: 0, flexShrink: 0 }}
        />
      </div>
    </div>
  );
};

export default CreateTemplate;
