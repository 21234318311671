import React from "react";

import Checkbox from "../Checkbox/Checkbox";
import Input from "../Input/Input";

const UploadForm = ({ formValidated, uploadCode }) => {
  return (
    <>
      <Input
        label="Whose podcard are you contributing to?"
        id="recipient"
        validated={formValidated}
      />
      <Input label="Your first name" id="firstName" validated={formValidated} />
      <Input label="Your last name" id="lastName" validated={formValidated} />
      <Input
        label="Your email address"
        id="email"
        type="email"
        validated={formValidated}
      />
      {uploadCode ? (
        <div>
          <b>Upload Code: {uploadCode}</b>
        </div>
      ) : (
        <Input label="Upload Code" id="uploadCode" validated={formValidated} />
      )}
      <div>
        <p>
          A Podcard is a downloadable sound file that can be easily shared
          online among friends and family. Your message may be heard by people
          other than those for whom it was originally intended. Do you
          understand that your Podcard message is not private?{" "}
          <span style={{ color: "red" }}>*</span>
        </p>
        <Checkbox
          id="notPrivate"
          label="Yes, I understand"
          validated={formValidated}
        />
        <p>
          I have read and agree to Podcard's Terms of Use and Privacy Policy,
          which includes receiving the occasional email.
          <span style={{ color: "red" }}>*</span>
        </p>
        <Checkbox id="termsOfUse" label="I agree" validated={formValidated} />
      </div>
    </>
  );
};

export default UploadForm;
