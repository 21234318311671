import "./playerStyles.css";

import React from "react";
import Player from "react-h5-audio-player";

import { UploadState } from "../../pages/Upload/UploadState";

const AudioPlayer = ({ src, state, onLoaded }) => {
  return (
    <>
      <Player
        preload="auto"
        autoPlayAfterSrcChange={false}
        src={src}
        onLoadedMetaData={onLoaded}
        onCanPlayThrough={onLoaded}
        onCanPlay={onLoaded}
        style={{
          display: state === UploadState.AUDIO_LOADED ? "block" : "none",
        }}
      />
    </>
  );
};

export default AudioPlayer;
