import { useRef, useState } from "react";

import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { firebaseAuth } from "../../Firebase";
import styles from "./Admin.module.css";

const UploadPodcard = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const inputRef = useRef(null);

  const handleClick = () => {
    // 👇️ open file input box on click of upload button
    inputRef.current.click();
  };

  const upload = (e) => {
    var request = new XMLHttpRequest();

    const fileObj = e.target.files && e.target.files[0];
    if (!fileObj) {
      alert("Please pick a valid file!");
      return;
    }
    e.target.value = null;

    const jobId = document.getElementById("jobId").value.trim();
    if (!jobId) {
      alert("Please enter valid job ID");
      return;
    }

    var formdata = new FormData();
    formdata.append("podcard", fileObj);
    formdata.append("jobId", jobId);

    setUploadProgress(0);
    request.upload.addEventListener(
      "progress",
      (e) => {
        setUploadProgress(Math.round((100 * e.loaded) / e.total));
      },
      false
    );

    request.open(
      "POST",
      "https://uploadpodcard-lxdlato5za-uc.a.run.app"
    );
    firebaseAuth.currentUser
      .getIdToken(true)
      .then(function (idToken) {
        request.setRequestHeader("Authorization", idToken);
        request.timeout = 600000;
        request.send(formdata);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className={styles.topBar}>
      <div className={styles.col1}>
        <span>Upload Podcard</span>
      </div>
      <div className={styles.col2}>
        <Input id="jobId" placeholder="Job ID" required={false} />
        <input
          style={{ display: "none" }}
          ref={inputRef}
          type="file"
          onChange={upload}
        />
      </div>
      <div className={styles.col3}>
        <Button
          onClick={handleClick}
          text="Upload"
          style={{ margin: 0, flexShrink: 0 }}
        />
        <ProgressBar
          completed={uploadProgress}
          containerStyle={{
            height: 8,
            width: 104,
            backgroundColor: "#e0e0de",
            marginTop: 2,
          }}
        />
      </div>
    </div>
  );
};

export default UploadPodcard;
