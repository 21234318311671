import "react-h5-audio-player/lib/styles.css";

import "./playerStyles.css";

import { initializeApp } from "firebase/app";
import { doc, getFirestore } from "firebase/firestore";
import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import AudioPlayer from "react-h5-audio-player";
import { useParams } from "react-router-dom";

import HeroImage from "../../assets/listen_hero.jpg";
import { ReactComponent as Loader } from "../../assets/loader.svg";
import Button from "../../components/Button/Button";
import styles from "./Listen.module.css";

const firebaseConfig = {
  apiKey: "AIzaSyBrWP8TkEJT_lcgmjPgXnHQxdBG_AGKUFA",
  authDomain: "podcardapp.firebaseapp.com",
  projectId: "podcardapp",
  storageBucket: "podcardapp.appspot.com",
  messagingSenderId: "1041902699599",
  appId: "1:1041902699599:web:eecb5f1a326122c7d3d63a",
  measurementId: "G-R9KF6LHXS1",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

const Listen = () => {
  const { jobId } = useParams();
  const [job, loading] = useDocumentData(doc(db, "jobs", jobId));
  console.log(job);

  const formatRecipient = (name, podcardType) => {
    if (podcardType === "Podcard for teams") {
      return name;
    } else {
      return name + "'s Podcard";
    }
  };

  const downloadUrl = (url) => {
    window.open(url, "_self");
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <img
            className={styles.heroImage}
            src={HeroImage}
            alt="Podcard illustration"
          />
          <h1 className={styles.title}>
            {formatRecipient(job.recipient, job.podcardType)}
          </h1>
          <p className={styles.subtitle}>
            Congratulations! You've been gifted a {job.podcardType}.
          </p>
          <p className={styles.subtitle}>Listen and download below</p>
          <div className={styles.playerArea}>
            <AudioPlayer
              autoPlayAfterSrcChange={false}
              src={job ? job.podcardLink.replace("www", "dl") : null}
            />
          </div>
          <div className={styles.button}>
            <Button
              text="Download"
              onClick={() =>
                downloadUrl(job.podcardLink.replace("dl=0", "dl=1"))
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default Listen;
