import React, { useEffect, useRef } from "react";
import fixWebmDuration from "webm-duration-fix";

import { UploadState } from "./UploadState";

// import Visualiser from "./Visualiser";

const MediaStreamRecorder = ({ setAudioData, state, stream }) => {
  const chunksRef = useRef([]);
  const recorderRef = useRef();

  const stopRecorder = () => {
    if (
      recorderRef.current &&
      recorderRef.current.state !== "inactive" &&
      recorderRef.current.stream
    ) {
      recorderRef.current.stop();
      recorderRef.current.stream.getTracks().forEach((track) => {
        if (track.readyState === "live") {
          track.stop();
        }
      });
    }
  };

  useEffect(() => {
    if (stream) {
      recorderRef.current = new MediaRecorder(stream);

      recorderRef.current.ondataavailable = function (e) {
        chunksRef.current.push(e.data);
      };

      recorderRef.current.onstop = async function (e) {
        if (chunksRef.current.length > 0) {
          var mimeType = chunksRef.current[0].type;

          var blob = new Blob([...chunksRef.current], { type: mimeType });

          if (mimeType.includes("webm")) {
            // Chrome doesn't add duration headers so we have to
            blob = await fixWebmDuration(
              new Blob([...chunksRef.current], { type: mimeType })
            );
          } else if (mimeType === "audio/mp4") {
            // WTF: this is need for playback on iOS for some reason
            // blob.type is audio/mp4 on iPad, passing this through doesn't work
            blob = new Blob([...chunksRef.current], {
              type: "audio/wav",
            });
            mimeType = "audio/aac";
          }
          const url = window.URL.createObjectURL(blob);
          setAudioData({ blob: blob, url: url, mimeType: mimeType });
        }
      };
    }
  }, [setAudioData, stream]);

  // Stop recording on unmount
  useEffect(() => {
    return () => {
      console.log("stopping from unmount");
      stopRecorder();
    };
  }, []);

  // State machine
  useEffect(() => {
    if (recorderRef.current) {
      switch (state) {
        case UploadState.NONE:
          console.log("state machine none stop");
          stopRecorder();
          break;
        case UploadState.RECORD_START:
          console.log("state machine start");
          recorderRef.current.start();
          break;
        case UploadState.RECORD_STOP:
          console.log("state machine stop");
          stopRecorder();
          break;
        default:
          break;
      }
    }
  }, [state]);

  return <></>;
};

export default MediaStreamRecorder;
