export const UploadState = Object.freeze({
  NONE: "NONE",
  RECORD_START: "RECORD_START",
  RECORD_PAUSE: "RECORD_PAUSE",
  RECORD_STOP: "RECORD_STOP",
  AUDIO_LOADED: "AUDIO_LOADED",
  AUDIO_LISTENED: "AUDIO_LISTENED",
  AUDIO_CONFIRMED: "AUDIO_CONFIRMED",
  FORM_COMPLETED: "FORM_COMPLETED",
  UPLOAD_COMPLETED: "UPLOAD_COMPLETED",
});
