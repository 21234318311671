import React from "react";

import styles from "./Checkbox.module.css";

const Checkbox = ({ label, id, validated = false }) => {
  const showError = validated && !document.getElementById(id).checkValidity();
  return (
    <div className={showError ? `${styles.base} ${styles.error}` : styles.base}>
      <input
        type="checkbox"
        id={id}
        name={id}
        required
        className={styles.input}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Checkbox;
