import React, { useEffect, useRef, useState } from "react";

import styles from "./ButtonPlay.module.css";

const labelStyle = {
  color: "black",
  fontWeight: 400,
  fontSize: "1.1em",
  flex: 1,
  display: "flex",
  alignItems: "center",
  paddingLeft: 10,
  cursor: "pointer",
};

const ButtonPlay = ({ src }) => {
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef(new Audio(src));

  const toggleAudio = () => {
    if (!playing) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    setPlaying((prev) => !prev);
  };

  useEffect(() => {
    if (audioRef.current) {
      const audio = audioRef.current;
      audio.ontimeupdate = (e) => {
        setProgress(100 * (audio.currentTime / audio.duration));
      };

      audio.onended = (e) => {
        setPlaying(false);
      };
    }
  }, [audioRef]);

  const seekbarClick = (e) => {
    var width = e.currentTarget.offsetWidth;
    var offsets = e.currentTarget.getBoundingClientRect();
    var left = offsets.left;
    var mouseX = e.clientX;
    var seek = (mouseX - left) / width;
    audioRef.current.currentTime = audioRef.current.duration * seek;
  };

  return (
    <div className={styles.container}>
      <button className={styles.button} onClick={toggleAudio}>
        {!playing ? (
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 1000 1000"
          >
            <path d="M68.2,936.1c0-45.3,0-839.6,0-870.8c0-40.5,41.9-70.6,83.3-46.9c33.2,19,696.1,401.8,752.7,434.5c37.2,21.5,36.5,72.8,0,94.3C863.4,571.3,198,956,149.8,982.8C114.5,1002.3,68.2,980.7,68.2,936.1z" />
          </svg>
        ) : (
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            height="35"
            width="35"
            viewBox="0 0 20 20"
          >
            <rect
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="16"
              x="3"
              y="2"
              rx="2"
              ry="2"
            />
            <rect
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="16"
              x="11"
              y="2"
              rx="2"
              ry="2"
            />
          </svg>
        )}
      </button>

      <div
        style={{
          ...labelStyle,
          background: `linear-gradient(90deg, #DDD 0%, #DDD ${progress}%, #FFF ${progress}%)`,
        }}
        onClick={seekbarClick}
      >
        This is what a podcard message sounds like!
      </div>
    </div>
  );
};

export default ButtonPlay;
