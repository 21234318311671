import {
  SAMLAuthProvider,
  getAuth,
  sendSignInLinkToEmail,
  signInWithPopup,
} from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import HeaderImage from "../../assets/podcard_full.jpg";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import styles from "./Login.module.css";

const auth = getAuth();

const samlProviders = { "jumpcloud.com": "saml.jumpcloudtest" };

const Login = () => {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [ssoInitiated, setSsoInitiated] = useState(false);
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate()

  const login = (e) => {
    e.preventDefault();

    const emailInput = document.getElementById("email");
    setValidated(true);
    if (!emailInput.checkValidity()) {
      return;
    }

    const email = emailInput.value;
    const domain = email.split("@").pop();
    if (domain in samlProviders) {
      setSsoInitiated(true);
      const provider = new SAMLAuthProvider(samlProviders[domain]);

      signInWithPopup(auth, provider)
        .then((res) => {
          console.log(res);
          navigate("/dashboard")
        })
        .catch((error) => {
          console.error(error.code);
          console.error(error.message);
        });
    } else {
      const actionCodeSettings = {
        url: "https://" + window.location.host + "/dashboard",
        handleCodeInApp: true,
      };
      sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailForSignIn", email);
          setEmailSubmitted(true);
        })
        .catch((error) => {
          console.error(error.code);
          console.error(error.message);
        });
    }
  };

  const cardContents = () => {
    if (emailSubmitted) {
      return (
        <>
          <h1>Check your email 💌</h1>
          <div>We've sent you a login link</div>
          <div style={{ padding: "15px 0 40px 0" }}>
            <b>Can't find it? </b>It may have gone to spam
          </div>
        </>
      );
    } else if (ssoInitiated) {
      return (
        <>
          <h1>SSO Login</h1>
          <div>Your login provider will pop-up</div>
          <div style={{ padding: "15px 0 40px 0" }}>
            <b>Can't see it? </b>Pop-ups may be blocked
          </div>
        </>
      );
    } else {
      return (
        <>
          <h1 className={styles.heading}>Share your story</h1>
          <form onSubmit={login}>
            <Input
              id="email"
              type="email"
              label="Enter email"
              required
              validated={validated}
              containerStyle={{
                textAlign: "left",
                margin: "40px 0 30px 0",
              }}
            />
            <Button
              type="submit"
              text="Login"
              style={{ margin: 0, width: "100%", boxSizing: "border-box" }}
            />
          </form>
        </>
      );
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <img
          className={styles.headerImage}
          src={HeaderImage}
          alt="Podcard Logo"
        />
        <div>{cardContents()}</div>
      </div>
    </div>
  );
};

export default Login;
