import React from "react";

const ProgressBar = ({ completed, containerStyle }) => {
  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: completed === 100 ? "#1abc9c" : "#2556b1",
    borderRadius: "inherit",
    textAlign: "right",
    transitionProperty: "background-color, width",
    transitionTimingFunction: "ease-in-out",
    transitionDuration: completed === 0 ? "0s" : "1s",
  };

  return (
    <div style={containerStyle}>
      <div style={fillerStyles}></div>
    </div>
  );
};

export default ProgressBar;
