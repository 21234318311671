import React from "react";

import styles from "./AvatarListItem.module.css";

const Avatar = () => {
  return (
    <div className={styles.avatar}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 20 20"
      >
        <title>user avatar</title>
        <path d="M10 12.5c-5.92 0-9 3.5-9 5.5v1h18v-1c0-2-3.08-5.5-9-5.5z" />
        <circle cx="10" cy="6" r="5" />
      </svg>
    </div>
  );
};

const AvatarListItem = ({ name }) => {
  return (
    <div className={styles.container}>
      <Avatar />
      <div className={styles.name}>{name}</div>
    </div>
  );
};

export default AvatarListItem;
