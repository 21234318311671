import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBrWP8TkEJT_lcgmjPgXnHQxdBG_AGKUFA",
  authDomain: "podcardapp.firebaseapp.com",
  projectId: "podcardapp",
  storageBucket: "podcardapp.appspot.com",
  messagingSenderId: "1041902699599",
  appId: "1:1041902699599:web:eecb5f1a326122c7d3d63a",
  measurementId: "G-R9KF6LHXS1",
};

initializeApp(firebaseConfig);

export const analytics = getAnalytics();
export const firebaseAuth = getAuth();
export const firestore = getFirestore();
