import React from "react";

import styles from "./Input.module.css";

const Input = ({
  label,
  type = "text",
  id,
  placeholder = "",
  required = true,
  validated = false,
  containerStyle = {},
}) => {
  return (
    <div className={styles.container} style={containerStyle}>
      <label className={styles.label} htmlFor={id}>
        <b>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </b>
      </label>
      <input
        className={validated ? `${styles.base} ${styles.input}` : styles.base}
        id={id}
        placeholder={placeholder}
        type={type}
        required={required}
      />
    </div>
  );
};

export default Input;
