import "react-h5-audio-player/lib/styles.css";

import Bowser from "bowser";
import { logEvent } from "firebase/analytics";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import FlowerBanner from "../../assets/flower_banner.jpg";
import PodCardFull from "../../assets/listen_hero.jpg";
import { ReactComponent as Loader } from "../../assets/loader.svg";
import PodCardExample from "../../assets/podcard_example.mp3";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import Button from "../../components/Button/Button";
import ButtonPlay from "../../components/ButtonPlay/ButtonPlay";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import UploadForm from "../../components/UploadForm/UploadForm";
import { analytics } from "../../Firebase";
import LegacyRecorder from "./LegacyRecorder";
import MediaStreamRecorder from "./MediaStreamRecorder";
import styles from "./Upload.module.css";
import { UploadState } from "./UploadState";

const Upload = () => {
  const location = useLocation();

  const [timeLeft, setTimeLeft] = useState(90);
  const [timeString, setTimeString] = useState("01:30");
  const [intervalID, setIntervalID] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [formValidated, setFormValidated] = useState(false);
  const [uploadStarted, setUploadStarted] = useState(false);

  const streamRef = useRef();

  const [useMediaStream, setUseMediaStream] = useState(true);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioURL, setAudioURL] = useState(null);
  const [mimeType, setMimeType] = useState(null);
  const [uploadState, setUploadState] = useState(UploadState.NONE);

  const getStream = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        if ("MediaRecorder" in window) {
          logEvent(analytics, "using_mediastream");
          setUseMediaStream(true);
        } else {
          logEvent(analytics, "not_using_mediastream");
          setUseMediaStream(false);
          alert(
            `Your browser is not up to date!\nRecording quality may be affected, please check the audio before submitting.`
          );
        }
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { channelCount: 1 },
        });
        return stream;
      } catch (e) {
        if (e instanceof DOMException) {
          // User rejected mic permissions
          alert("Please enable microphone permissions to record");
        }
        throw e;
      }
    } else {
      logEvent(analytics, "recording_not_supported");
      alert("Recording not supported on your browser!");
    }
  };

  const start = async () => {
    try {
      streamRef.current = await getStream();
      setUploadState(UploadState.RECORD_START);
      setIntervalID(setInterval(decrementTime, 1000));
      logEvent(analytics, "recorder_start");
    } catch (e) {
      console.error(e);
    }
  };

  const setAudioData = useCallback(({ blob, url, mimeType }) => {
    setAudioBlob(blob);
    setAudioURL(url);
    setMimeType(mimeType);
    if (blob == null) {
      logEvent(analytics, "undefined_blob");
      alert(
        "No audio detected! Please try recording again and report this issue!"
      );
    } else if (blob.size === 0) {
      logEvent(analytics, "empty_blob");
      alert(
        "No audio detected! Please try recording again and report this issue!"
      );
    }
    return;
  }, []);

  const stop = useCallback(() => {
    setUploadState(UploadState.RECORD_STOP);
    intervalID && clearInterval(intervalID);
    logEvent(analytics, "recorder_stop");
    return;
  }, [intervalID]);

  const restart = () => {
    setUploadState(UploadState.NONE);
    setTimeLeft(90);
    setUploadProgress(0);
    setFormValidated(false);
  };

  useEffect(() => {
    var date = new Date(timeLeft * 1000);
    setTimeString(date.toISOString().substring(14, 19));
    if (timeLeft === 0) {
      stop();
    }
  }, [timeLeft, stop]);

  const decrementTime = () => {
    setTimeLeft((prevTimeLeft) => --prevTimeLeft);
  };

  const upload = () => {
    const recipient = document.getElementById("recipient");
    const firstName = document.getElementById("firstName");
    const lastName = document.getElementById("lastName");
    const email = document.getElementById("email");
    const notPrivate = document.getElementById("notPrivate");
    const termsOfUse = document.getElementById("termsOfUse");

    let uploadCodeInput = undefined;
    if (!location.pathname.substring(1)) {
      uploadCodeInput = document.getElementById("uploadCode");
    }

    setFormValidated(true);
    if (
      !recipient.checkValidity() ||
      !firstName.checkValidity() ||
      !lastName.checkValidity() ||
      !email.checkValidity() ||
      (uploadCodeInput && !uploadCodeInput.checkValidity()) ||
      !notPrivate.checkValidity() ||
      !termsOfUse.checkValidity()
    ) {
      return;
    }
    setUploadStarted(true);

    var formdata = new FormData();
    formdata.append("audioData", audioBlob);
    formdata.append("mimeType", mimeType);
    formdata.append("recipient", recipient.value);
    formdata.append("firstName", firstName.value);
    formdata.append("lastName", lastName.value);
    formdata.append("email", email.value);
    formdata.append(
      "userAgent",
      JSON.stringify(Bowser.parse(window.navigator.userAgent))
    );
    const uploadCode = uploadCodeInput
      ? uploadCodeInput.value
      : location.pathname.substring(1);
    formdata.append("jobCode", uploadCode);

    var request = new XMLHttpRequest();

    request.upload.addEventListener(
      "progress",
      (e) => {
        // console.log(e);
        setUploadProgress(Math.round((100 * e.loaded) / e.total));
      },
      false
    );

    request.upload.addEventListener(
      "load",
      () => {
        setUploadState(UploadState.UPLOAD_COMPLETED);
      },
      false
    );

    request.open(
      "POST",
      "https://uploadfile-lxdlato5za-uc.a.run.app"
    );
    request.timeout = 60000;
    request.send(formdata);
  };

  const buttonArea = () => {
    switch (uploadState) {
      case UploadState.AUDIO_LOADED:
        return (
          <span>
            <Button text="Redo" onClick={restart} outline />
            <Button
              text="Finish"
              onClick={() => setUploadState(UploadState.AUDIO_LISTENED)}
            />
          </span>
        );
      case UploadState.AUDIO_LISTENED:
        return (
          <span>
            <Button
              text="Back"
              onClick={() => setUploadState(UploadState.RECORD_STOP)}
              outline
            />
            <Button
              text="Confirm"
              onClick={() => setUploadState(UploadState.AUDIO_CONFIRMED)}
            />
          </span>
        );
      case UploadState.AUDIO_CONFIRMED:
        return (
          <Button text="Submit" disabled={uploadStarted} onClick={upload} />
        );
      default:
        return;
    }
  };

  const recorder = () => {
    if (useMediaStream) {
      return (
        <MediaStreamRecorder
          setAudioData={setAudioData}
          state={uploadState}
          stream={streamRef.current}
        />
      );
    } else {
      return (
        <LegacyRecorder
          setAudioData={setAudioData}
          state={uploadState}
          stream={streamRef.current}
        />
      );
    }
  };

  const mainArea = (uploadState) => {
    switch (uploadState) {
      case UploadState.NONE:
        return (
          <>
            <button
              id="record"
              className={`${styles.control} ${styles.start}`}
              onClick={start}
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 80 80"
              >
                <circle cx="40" cy="40" r="30" />
              </svg>
            </button>
            {recorder()}
          </>
        );
      case UploadState.RECORD_START:
        return (
          <>
            <button
              id="stop"
              className={`${styles.control} ${styles.stop}`}
              onClick={stop}
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 80 80"
              >
                <rect x="13" y="13" width="55" height="55" rx="10" />
              </svg>
            </button>
            {recorder()}
          </>
        );
      case UploadState.RECORD_STOP:
      case UploadState.AUDIO_LOADED:
        return (
          <>
            {uploadState === UploadState.RECORD_STOP && <Loader />}
            <AudioPlayer
              src={audioURL}
              state={uploadState}
              onLoaded={() => {
                console.log("setting");
                setUploadState(UploadState.AUDIO_LOADED);
              }}
            />
          </>
        );
      case UploadState.AUDIO_LISTENED:
        return (
          <div className={styles.confirm}>
            Confirming you want to submit this audio?
          </div>
        );
      case UploadState.AUDIO_CONFIRMED:
        return (
          <>
            <UploadForm
              formValidated={formValidated}
              uploadCode={location.pathname.substring(1)}
            />
            <ProgressBar
              completed={uploadProgress}
              containerStyle={{
                height: 8,
                width: "80%",
                backgroundColor: "#e0e0de",
                borderRadius: 50,
                margin: 30,
              }}
            />
          </>
        );
      case UploadState.UPLOAD_COMPLETED:
        return (
          <div className={styles.confirmation}>
            <p>Thanks for submitting a message to Podcard.</p>
            <p>Your friend is going to love it!</p>
          </div>
        );
      default:
        return recorder();
    }
  };

  return (
    <>
      <div className={styles.intro}>
        <div className={styles.header}>
          <p>Hi there 👋</p>
          <p>
            You've been invited to record a message for a <b>Podcard</b>!
          </p>
          <p>
            A podcard is a personalised podcast filled with audio messages from
            friends, family and colleagues.
          </p>
          <p>Read the instructions below to record and upload your message.</p>
          <p>
            And if you're reading this on your computer... HOLD UP ✋... you
            should switch to your phone before recording your message -- the
            audio gods will thank you! 😌
          </p>
        </div>
        <img className={styles.titleImage} src={PodCardFull} alt="Podcard" />
        <dl className={styles.steps}>
          <dt>Step 1</dt>
          <dd>
            Find a quiet room - carpeted rooms are best. Turn off any whirring
            fans or heaters.
          </dd>
          <dt>Step 2</dt>
          <dd>
            Cradle your phone in your palm - be sure to use your phone to record
            the message, not a computer. Hold the phone directly in front of
            your face, such that the microphone is positioned 5cms in front of
            your mouth.
          </dd>
          <dt>Step 3</dt>
          <dd>
            Record your message, sharing a specific memory of your friend or a
            story of a time you shared. For a wedding podcard, share a memory of
            an experience you had with the couple, or a reflection on how you've
            seen their relationship shape them as individuals.
          </dd>
          <dt>Step 4</dt>
          <dd>
            Follow the prompts below to submit your message. And if you have any
            issues, please don't hesitate to get in touch with us at{" "}
            <a href="mailto:hello@podcard.com">hello@podcard.com</a>
          </dd>
        </dl>
        <div>
          <img
            className={styles.flowerBannerTop}
            src={FlowerBanner}
            alt="Flower banner"
          />
          <ButtonPlay src={PodCardExample} />
          <img
            className={styles.flowerBannerBottom}
            src={FlowerBanner}
            alt="Flower banner"
          />
        </div>
        <h3>Tips for recording the perfect podcard message</h3>
        <ul>
          <li>
            <b>Be specific: </b>Describe your memory in detail. And try to
            recall how being in that moment made you feel. The best podcard
            messages contain a single evocative story or memory.
          </li>
          <li>
            <b>Have fun: </b>Along with your message, consider playing some
            original music, reading a poem, or recording audio with your
            children saying funny things - these sounds enrich a podcard
            enormously!
          </li>
          <li>
            <b>Use your name: </b>Say your first name at the start of the
            message, so the listener can keep track of who is talking.
          </li>
          <li>
            <b>Be direct: </b>Speak directly to the podcard recipient in the
            message.
          </li>
          <li>
            <b>Be yourself: </b>Try to avoid writing your message down. It'll
            sound more natural if you speak off the cuff, like you're having an
            everyday conversation.
          </li>
          <li>
            <b>Be concise: </b>Keep messages short and snappy, 90 seconds max.
          </li>
        </ul>
        <p>
          Many people find the experience of recording themselves daunting. Try
          to remember you're speaking directly to your friend. Don't worry about
          what you sound like. If you speak from the heart, we promise you'll
          sound great!
        </p>
      </div>
      <div
        className={
          uploadState === UploadState.RECORD_START
            ? styles.timeRecording
            : styles.time
        }
      >
        {timeString}
      </div>
      <div className={styles.mainArea}>{mainArea(uploadState)}</div>
      <div className={styles.buttonArea}>{buttonArea()}</div>
    </>
  );
};

export default Upload;
