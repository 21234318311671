import React from "react";

import styles from "./Button.module.css";

const Button = ({ text, disabled, onClick, style = {}, outline = false }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={style}
      className={outline ? styles.outline : styles.button}
    >
      {text}
    </button>
  );
};

export default Button;
