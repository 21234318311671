import AvatarListItem from "../../components/AvatarListItem/AvatarListItem";
import Button from "../../components/Button/Button";
import CopyText from "../../components/CopyText/CopyText";
import styles from "./Dashboard.module.css";

const JobsList = ({ authLoading, authError, jobs, jobsLoading, jobsError }) => {
  const addDays = (initDate, days) => {
    var date = initDate.toDate();
    date.setDate(date.getDate() + days);
    return date.toDateString();
  };

  const downloadUrl = (url) => {
    window.open(url, "_self");
  };

  const formatRecipient = (name, podcardType) => {
    if (podcardType === "Podcard for teams") {
      return name;
    } else {
      return name + "'s Podcard";
    }
  };

  return (
    <>
      <div>
        {authError && <p>Error: {JSON.stringify(authError)}</p>}
        {jobsError && <p>Error: {JSON.stringify(jobsError)}</p>}
      </div>
      {jobsLoading || authLoading ? (
        <div className={styles.loader}></div>
      ) : (
        jobs &&
        jobs.map((job, i) => (
          <div className={styles.job} key={i}>
            <h2 className={styles.jobTitle}>
              {formatRecipient(job.recipient, job.podcardType)}
            </h2>
            <div>
              {job.submitted ? (
                <>
                  <h3>Contributors</h3>
                  {job.submitted.map((name, j) => (
                    <AvatarListItem key={j} name={name} />
                  ))}
                </>
              ) : (
                <div>No submissions yet!</div>
              )}
            </div>
            <h3>Submission Link</h3>
            <CopyText
              text={`https://${window.location.host}/${job.orderNumber}`}
            />
            <div>
              {job.orderDate && (
                <>
                  <h3>Deadline for Submissions</h3>
                  <CopyText
                    deadline={true}
                    text={`${addDays(job.orderDate, 7)}, 11.30pm AEST`}
                  />
                </>
              )}
            </div>
            {/* <h3>Share Link</h3>
            <CopyText
              text={`https://${window.location.host}/listen/${job.orderNumber}`}
            /> */}
            <h3>Listen Here</h3>
            {job.podcardLink ? (
              <div className={styles.downloadArea}>
                <audio controls>
                  <source src={job.podcardLink.replace("www", "dl")} />
                </audio>
                <Button
                  text="Download"
                  onClick={() =>
                    downloadUrl(job.podcardLink.replace("dl=0", "dl=1"))
                  }
                />
              </div>
            ) : (
              <div className={styles.downloadText}>
                Your completed podcard will be available to stream or download
                here on {job.dueDate && addDays(job.dueDate, -1)}
              </div>
            )}
          </div>
        ))
      )}
    </>
  );
};

export default JobsList;
