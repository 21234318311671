import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
} from "firebase/auth";
import { collection, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";

import HeroImage from "../../assets/listen_hero.jpg";
import { ReactComponent as Loader } from "../../assets/loader.svg";
import AvatarListItem from "../../components/AvatarListItem/AvatarListItem";
import Button from "../../components/Button/Button";
import CopyText from "../../components/CopyText/CopyText";
import { firebaseAuth, firestore } from "../../Firebase";
import styles from "./Dashboard.module.css";

const Dashboard = () => {
  const [promptLogin, setPromptLogin] = useState(true);
  const [user, authLoading, authError] = useAuthState(firebaseAuth);
  const [jobs, jobsLoading, jobsError] = useCollectionData(
    query(
      collection(firestore, "jobs"),
      where("owner", "==", user && user.email)
    )
  );

  let navigate = useNavigate();

  // If not logged in then prompt to do so
  useEffect(() => {
    if (!authLoading && user == null) {
      setPromptLogin(true);
    } else {
      setPromptLogin(false);
    }
  }, [authLoading, user]);

  useEffect(() => {
    if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(firebaseAuth, email, window.location.href)
        .then((result) => {
          // Clear email from storage and clear url params
          window.localStorage.removeItem("emailForSignIn");
          window.history.replaceState(null, null, window.location.pathname);
          // You can access the new user via result.user
        })
        .catch((error) => {
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, []);

  const logout = () => {
    signOut(firebaseAuth)
      .then(() => {
        // Sign-out successful.
        navigate("/login");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const addDays = (initDate, days) => {
    var date = initDate.toDate();
    date.setDate(date.getDate() + days);
    return date.toDateString();
  };

  const downloadUrl = (url) => {
    window.open(url, "_self");
  };

  const formatRecipient = (name, podcardType) => {
    if (podcardType === "Podcard for teams") {
      return name;
    } else {
      return name + "'s Podcard";
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <span className={styles.email}>
          {authLoading ? "Loading..." : user && user.email}
        </span>
        {!promptLogin && (
          <Button
            text="Logout"
            outline
            onClick={logout}
            style={{ margin: "0 0 0 12px", flexShrink: 0 }}
          />
        )}
      </div>
      <img
        className={styles.heroImage}
        src={HeroImage}
        alt="Illustration of family"
      />
      <div className={styles.instructions}>
        <strong
          style={{
            width: "100%",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          Thanks for buying a Podcard!
        </strong>
        <br></br>
        <br></br>
        The best podcards are filled with loving messages from all of the
        podcard recipient's favourite people - friends, family, colleagues,
        neighbours, children, babies and sometimes even pets!
        <br></br>
        <br></br>
        Your job now is to invite people to contribute a message for your
        project:
        <ol>
          <li>
            Explain why you're organising a podcard, and why you'd love for the
            person to contribute a message
          </li>
          <li>
            Send them the submission link, where they can record and upload
            their message
          </li>
          <li>Let them know the deadline for submissions</li>
          <li>Swear them to secrecy. A podcard is a surprise!</li>
        </ol>
        We'll touch base with you again 48-hours before the deadline for
        submissions, to give you an extra opportunity to chase any stragglers
        <br></br>
        <br></br>
        Any questions, please don't hesitate to get in touch at{" "}
        <a href="mailto:hello@podcard.com">hello@podcard.com</a>.<br></br>
        <br></br>
        Now, go forth and gather together your community. We promise a little
        hustling now will deliver a beautiful result!
      </div>
      <div>
        {promptLogin && (
          <strong>
            It appears you aren't logged in!{" "}
            <a
              href={
                window.location.protocol +
                "//" +
                window.location.host +
                "/login"
              }
            >
              Click here to login.
            </a>
          </strong>
        )}

        <div>
          {authError && <p>Error: {JSON.stringify(authError)}</p>}
          {jobsError && <p>Error: {JSON.stringify(jobsError)}</p>}
        </div>
        <div className={styles.header}>
          <h1>Your Podcards</h1>
        </div>

        {jobsLoading || authLoading ? (
          <Loader style={{ width: "100%" }} />
        ) : (
          jobs &&
          jobs.map((job, i) => (
            <div className={styles.job} key={i}>
              <h2 className={styles.jobTitle}>
                {formatRecipient(job.recipient, job.podcardType)}
              </h2>
              <div>
                {job.submitted ? (
                  <>
                    <h3>Contributors</h3>
                    {job.submitted.map((name, j) => (
                      <AvatarListItem key={j} name={name} />
                    ))}
                  </>
                ) : (
                  <div>No submissions yet!</div>
                )}
              </div>
              <h3>Submission Link</h3>
              <CopyText
                text={`https://${window.location.host}/${job.orderNumber}`}
              />
              <div>
                {job.orderDate && (
                  <>
                    <h3>Deadline for Submissions</h3>
                    <CopyText
                      deadline={true}
                      text={`${addDays(job.orderDate, 7)}, 11.30pm AEST`}
                    />
                  </>
                )}
              </div>
              {/* <h3>Share Link</h3>
              <CopyText
                text={`https://${window.location.host}/listen/${job.orderNumber}`}
              /> */}
              <h3>Listen Here</h3>
              {job.podcardLink ? (
                <div className={styles.downloadArea}>
                  <audio controls>
                    <source src={job.podcardLink.replace("www", "dl")} />
                  </audio>
                  <Button
                    text="Download"
                    onClick={() =>
                      downloadUrl(job.podcardLink.replace("dl=0", "dl=1"))
                    }
                  />
                </div>
              ) : (
                <div className={styles.downloadText}>
                  Your completed podcard will be available to stream or download
                  here on {job.dueDate && addDays(job.dueDate, -1)}
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Dashboard;
